module.exports = {
  title: "AquaGIS",
  domain: "localhost:8000",
  apiBaseURL: process.env.VUE_APP_BASE_URL,
  valvesBaseURL: process.env.VUE_APP_VALVES_URL,
  keycloakURL: process.env.VUE_APP_KEYCLOAK_URL,
  version: 'v1.0.6 - 05-06-2024',
  requestTimeout: 10, // in minutes
  removeTokenAfterBrowserClose: false,
}
